import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import Search from './Search';
import { deferExecutor } from 'app/DeferredOnLocation';
import { getBrandCode, getBrandName, getBrandIconUrl } from 'utils/brand';
import { LicenseManager } from 'ag-grid-enterprise';
import Confirm from 'app/components/Confirm';
import SessionOverlay from 'app/components/SessionOverlay';
import ChatOverlay from 'app/components/ChatOverlay';
import { selectUser, selectAlerts } from 'data/user/selectors';
import Alert from 'app/components/Alert';
import { getSavedSearches, selectSavedSearchLoading, selectSavedSearches } from 'data/search';
import { selectLoading, selectPropertyExport, selectPropertyExportLoading, confirmPropertyExport, getPropertyExport, selectPendingPropertyExportId } from 'data/property';
import PopupHolder from './PopupHolder';
import ErrorDisplay from './components/ErrorDisplay';
import Sidebar from './components/Sidebar';
import css from './style.scss';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS } from '../config/constants/API';
const meta = [];
const htmlAttributes = { lang: 'en' };

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-059306}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Equimine}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Equimine}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Equimine}_need_to_be_licensed___{Equimine}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{28_May_2025}____[v3]_[01]_MTc0ODM4NjgwMDAwMA==a479128714613ea5a2ce1ab951fe8c4b')
moment.tz.setDefault('Etc/UTC');

class App extends Component {
  static showAlerts;
  constructor(props) {
    super(props);
    this.queueRefresh = this.queueRefresh.bind(this);
    this.refresh = this.refresh.bind(this);
    this.pushRoute = this.pushRoute.bind(this);
    this.setGA = this.setGA.bind(this);
    this.refreshTimer = null;
    this.savedSearchCounter = 0;
  }
  setGA() {
    ReactGA.initialize(`${GOOGLE_ANALYTICS}`);
    ReactGA.pageview('Init page view');
  };

  componentDidMount() {
    this.setGA();
    const { showAlerts, alerts, initialPath } = this.props;

    if (document.body) {
      document.body.classList.add(`theme-${getBrandCode()}`);
      window.pushRoute = this.pushRoute;
      this.queueRefresh();
    }

    if (App.showAlerts && alerts && alerts.size) {
      App.showAlerts = false;
      showAlerts({ alerts: alerts.toJS() });
    }

    if (initialPath) {
      setTimeout(() => {
        this.pushRoute(`/${initialPath}`);
      }, 1);
    }
  }

  componentWillReceiveProps(props) {
    const { loading, propertyExport, confirmPropertyExport } = props;

    if (!loading) {
      if (propertyExport) {
        const file = propertyExport.toJS();
        confirmPropertyExport(file.id, () => this.loadFile(file));
      }

      if (!this.refreshTimer) this.queueRefresh();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { location: loc } = this.props;
    const { location: nextLoc, propertyExport } = nextProps;
    return loc.pathname !== nextLoc.pathname || loc.query !== nextLoc.query || !!propertyExport;
  }

  queueRefresh() {
    this.refreshTimer = setTimeout(this.refresh, 30000);
  }
  refresh() {
    const { savedSearches, pendingPropertyExportId, getSavedSearches, getPropertyExport } = this.props;

    this.refreshTimer = null;

    if (pendingPropertyExportId) getPropertyExport(pendingPropertyExportId);
    else if (savedSearches.find(s => s.get('stale'))) {
      getSavedSearches(true);
      this.savedSearchCounter = 0;
    } else this.queueRefresh();
  }
  loadFile(file) {
    const { alert } = this.props;
    const { base64Uri, name, prompt } = file;

    const downloadLink = document.createElement('a');

    downloadLink.href = base64Uri;
    downloadLink.download = name;

    downloadLink.click();

    if (prompt) alert(`Your export file "${name} has been downloaded.`);
  }

  pushRoute(path) {
    this.props.router.push(path);
  }

  render() {
    const { children, location } = this.props;
    const { pathname, search } = location;
    const title = getBrandName();
    const searchVisible = pathname.startsWith('/search');

    return (
      <div>
        <Helmet
          defaultTitle={title}
          titleTemplate={`%s - ${title}`}
          meta={meta}
          htmlAttributes={htmlAttributes}
        >
          <link rel="icon" type="image/png" href={getBrandIconUrl()} />
        </Helmet>
        <PopupHolder />
        <ErrorDisplay />
        <SessionOverlay />
        <ChatOverlay />
        <div className={css.wrapper}>
          <Sidebar location={`${pathname}${search}`} />
          <Search visible={searchVisible} />
          <div>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
App.showAlerts = true;

App.propTypes = {
  children: PropTypes.node,
};

export default withRouter(connect(state => ({
  savedSearches: selectSavedSearches(state),
  propertyExport: selectPropertyExport(state),
  alerts: selectAlerts(state),
  initialPath: selectUser(state).get('initialPath'),
  pendingPropertyExportId: selectPendingPropertyExportId(state),
  loading: selectSavedSearchLoading(state) || selectLoading(state) || selectPropertyExportLoading(state),
}), {
  getSavedSearches,
  getPropertyExport,
  confirmPropertyExport,
  alert: Confirm.alert,
  showAlerts: Alert.open,
})(deferExecutor(App)));