/** @flow */
import React from 'react';
import { Map } from 'immutable';
import { Link } from 'react-router-dom'; // Updated import
import stopPropagation from 'utils/DOM/propagation';

import css from './style.scss';

export default function renderAddressCell(address, indexes, item, _, extraProps) {
  const streetAddress = address.get('streetAddress', '');
  const cityName = address.get('cityName', '');
  const id = item.has('propertyId') ? item.get('propertyId') : item.get('id');
  const url = `/search/${id}`;
  const fullAddress = `${streetAddress} ${cityName}`;
  const staticAddress = extraProps && extraProps.staticAddress;

  return (
    <div className={staticAddress ? null : css.address}>
      {staticAddress ? fullAddress : <Link to={url} className={css.addressLink} onClick={stopPropagation}>{fullAddress}</Link>}
    </div>
  );
}
