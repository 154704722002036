import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';

import { Link } from 'react-router-dom';
import SVG from 'components/base/SVG';
import Modal from 'components/base/Modal';
import Button, { SolidButton } from 'components/Button';
import Checkbox from 'components/base/Checkbox';
import { getPopupRegistration, closePopup, openPopup, Priority } from 'app/PopupHolder';
import { saveEmailCampaign, selectLoading, selectEmailCampaign, MarketingCampaignStatuses } from 'data/campaigns';
import { selectProfile } from 'data/user';
import CheckoutSummary from 'app/components/CheckoutSummary';
import Confirm from 'app/components/Confirm';
import { validate } from 'utils/validation';

import Preview from '../Preview';
import css from './style.scss';


class PostcardCheckout extends PureComponent {
  constructor(props) {
    super(props);

    this.handlePurchase = this.handlePurchase.bind(this);
    this.handleSummaryLoad = this.handleSummaryLoad.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = { checks: [false, false, false], message: null };
  }

  handlePurchase() {
    const { checks } = this.state;
    const { campaign, confirm, saveEmailCampaign, closePopup } = this.props;

    if (checks.findIndex(c => !c) >= 0) confirm({ cancelLabel: null, question: 'Please review your order and check all boxes.' });
    else {
      confirm({
        caption: 'Confirm Publish',
        okLabel: 'Publish',
        question: 'Publish campaign? Once published the email campaign will be activated and its settings & recipients cannot be changed.',
        onOk: () => {
          validate(() => {
            const { paymentMethodId, cardCode, paymentAmount, creditAmount } = this.summaryApi.validate();

            saveEmailCampaign({ ...campaign.toJS(), status: MarketingCampaignStatuses.PROCESSING }, false, paymentMethodId, cardCode, paymentAmount, creditAmount, () => {
              const { status, message } = campaign.toJS();

              if (status) {
                setTimeout(() => {
                  closePopup();
                  confirm({ cancelLabel: null, question: 'Congratulations! Your order was submitted successfully and will be processed within 24 hours.' });
                }, 1);
              } else this.setState({ message });
            });
          });
        },
      });
    }
  }

  handleSummaryLoad(api) {
    this.summaryApi = api;
  }

  handleChange(ev) {
    const { name, checked } = ev.target || ev;
    const checks = this.state.checks.slice();
    checks[Number(name)] = checked;

    this.setState({ checks });
  }

  render() {
    const { loading, closePopup, campaign, profile } = this.props;
    const { checks, message } = this.state;

    const btnProps = { loading };
    const businessAddressError = message === 'ERROR_BUSINESS_ADDRESS';

    const errors = campaign.get('errors');
    const lines = campaign.get('lines').toJS();
    const lines2 = lines.slice();
    lines2.push({ ...lines[0], description: 'Total' });

    const writeField = (label, value, html = false) => (!value ? null : (
      <div className={css.emailField}>
        <div className={css.emailFieldLabel}>{label}:</div>
        {html ? <div className={css.emailFieldValue} dangerouslySetInnerHTML={{ __html: value }} /> : <div className={css.emailFieldValue}>{html ? null : value}</div>}
      </div>
    ));

// { amount: rate, quantityMin: 1, quantityMax: 500 }
    return (
      <Modal isOpen uniqId="PostcardCheckout" width="auto" padding="35px 30px">
        <div className={css.checkout}>
          <div className={css.header}>Finalize Order</div>
          <div className={css.body}>
            <div className={css.left}>
              <div className={css.emailFields}>
                {writeField('Friendly From', campaign.get('friendlyFrom'))}
                {writeField('Reply To', campaign.get('replyToEmail'))}
                {writeField('Subject', campaign.get('subject'))}
                {writeField('Pre-Header', campaign.get('preHeader'), true)}
              </div>
              <div style={{ height: '500px' }}>
                <Preview confirmation />
              </div>
            </div>
            <div className={css.right}>
              <div className={css.content}>
                <CheckoutSummary
                  lines={lines2}
                  rate={lines[0]}
                  instructions="Please review your email layout and click confirm to proceed with your order."
                  onLoad={this.handleSummaryLoad}
                />
                {errors ? (
                  <div className={css.errors}>
                    <div>To help ensure email deliverability, please correct the following:</div>
                    <ul>
                      {errors.map(error => <li>{error}</li>)}
                    </ul>
                  </div>
                ) : (
                  <div>
                    <div className={css.important}>
                      <div>
                        <SVG icon="iconWarning" />
                        Important
                      </div>
                      <div>
                        Finalize your email campaign by completing the checklist below.<br />
                        ALL checkboxes are required in order to process your order.
                      </div>
                    </div>
                    <div className={css.checklist}>
                      <Checkbox checked={checks[0]} name="0" onClick={this.handleChange} label="Does your email content look correct?" />
                      <Checkbox checked={checks[1]} name="1" onClick={this.handleChange} label="Is your friendly from & reply to correct?" />
                      <Checkbox checked={checks[2]} name="2" onClick={this.handleChange} label="Is your business name and address correct?" />
                    </div>
                    <div className={css.instructions}>
                      <div>If you are satisfied with your template please press &quot;place order.&quot; To make changes, click cancel and return to the editor. By clicking place order, your understand no changes can be made after your order is placed.</div>
                      <div>*Note: Orders are typically processed within 24 hours.</div>
                    </div>
                  </div>
                )}
              </div>
              {!message || businessAddressError ? null : <div className={css.message}>{message}</div>}
              {!businessAddressError ? null : <div className={css.message}>ERROR - Business Address Required. A valid business address is required to send mail. <Link to="/account" onClick={closePopup}>Click here</Link> to update the business address on file.</div>}
              <div className={css.buttons}>
                <Button {...btnProps} onClick={closePopup}>{errors ? 'Close' : 'Cancel'}</Button>
                {errors ? null : <SolidButton {...btnProps} onClick={this.handlePurchase}>Place Order</SolidButton>}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const PostcardCheckoutPopup = connect(state => ({
  loading: selectLoading(state),
  campaign: selectEmailCampaign(state),
  profile: selectProfile(state),
}), {
  saveEmailCampaign,
  confirm: Confirm.open,
})(PostcardCheckout);

const registrationId = getPopupRegistration(PostcardCheckoutPopup);
PostcardCheckoutPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
PostcardCheckoutPopup.close = () => closePopup({ popup: registrationId });

export default PostcardCheckoutPopup;
