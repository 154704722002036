import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Table, { Column } from 'components/base/Table';
import tableCss from 'components/base/Table/styles.scss';

import Confirm from 'app/components/Confirm';
import SVG from 'components/base/SVG';
import { selectSavedSearches, selectSavedSearchLoading, loadSearch, deleteSearch } from 'data/search';
import { dropdownable } from 'components/base/Dropdown';

import SavedSearchAssign from './SavedSearchAssign';
import css from './style.scss';

const deleteClass = classNames(css.inlineButton, css.tableBtn, tableCss.hidden);

const deleteRenderer = (x, y, item, z, { onClick }) => (
  item.get('locked') ? null : <button onClick={ev => onClick(ev, item)} className={deleteClass} disabled={!item.get('id')}>Delete</button>
);
const detailRenderer = val => <div className={css.details} title={val}>{val}</div>;

const noop = () => { };

class AllSearches extends PureComponent {
  constructor(props) {
    super(props);

    this.scrollCallback = noop;
    this.handleRowClick = this.handleRowClick.bind(this);
    this.setRowActive = this.setRowActive.bind(this);
    this.setActiveRowClassName = this.setActiveRowClassName.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.registerScrollCallback = this.registerScrollCallback.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleDropdownClose = this.toggleDropdownClose.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.wrapperRef = null;
    this.tableWrapperRef = null;
    this.state = { activeRow: null, activeRowClassName: null, isAllSearchesOpen: false };
  }

  componentWillReceiveProps(nextProps) {
    const { loading, closeDropdown } = this.props;
    if (nextProps.loading && !loading) closeDropdown();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isAllSearchesOpen: false });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setRowActive(index) {
    this.setState({ activeRow: index });
  }

  setActiveRowClassName(cls) {
    this.setState({ activeRowClassName: cls });
  }

  registerScrollCallback(cb) {
    this.scrollCallback = cb;
  }

  handleRowClick(event, item) {
    const { loadSearch, closeDropdown } = this.props;

    loadSearch(item.toJS());
    closeDropdown();
  }

  handleScroll(params) {
    if (params.target === this.tableWrapperRef) this.scrollCallback(params);
  }

  handleRef(ref) {
    this.tableWrapperRef = ref;
  }

  handleDelete(ev, item) {
    const { confirm, deleteSearch, closeDropdown } = this.props;

    ev.stopPropagation();

    confirm({
      onOk: () => { deleteSearch(item.get('id')); closeDropdown(); },
      question: `Are you sure that you want to delete search "${item.get('name')}"?`,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getFavoriteSearchCount() {
    // Find all elements with a class name containing "__dashboardSearchItem"
    // This helps identifying the visible search buttons
    const searchItems = document.querySelectorAll(
      '[class*="__dashboardSearchItem"]',
    );
    return searchItems.length;
  }

  toggleDropdown(event) {
    this.setState(prevState => ({
      isAllSearchesOpen: !prevState.isAllSearchesOpen,
    }));
  }

  toggleDropdownClose(event) {
    event.stopPropagation(); // Prevent this event from triggering the document's click listener
    this.setState(prevState => ({
      isAllSearchesOpen: !prevState.isAllSearchesOpen,
    }));
  }

  render() {
    const { onClick, onRef, loading, disabled, isOpen, items, closeDropdown, query } = this.props;
    const { activeRow, activeRowClassName, isAllSearchesOpen } = this.state;

    const assignRenderer = (w, indexes, item, z, props) => <SavedSearchAssign {...{ ...props, item, indexes, query }} />;

    return (
      <div className={isOpen ? `${css.allSearches} ${css.active}` : css.allSearches} onClick={onClick} disabled={disabled}>
        <div className={css.searchContainer}>
          <SVG icon={isOpen ? 'iconFolderWhite' : 'iconFolderOrange'} className={css.icon} />
          <div className={css.label}>{loading ? 'Loading' : 'All Searches'}</div>
        </div>
        {!isOpen ? null : (
          <div className={`${css.root} ${this.getFavoriteSearchCount() < 4 ? css.leftAlign : ''}`} ref={onRef}>
            <div className={css.header}>Here’s a list of all your saved searches</div>
            <div className={css.tableWrapper} onScroll={this.handleScroll} ref={this.handleRef}>
              <Table data={items} activeRow={activeRow} activeRowClassName={activeRowClassName} isHoverable className="type_6" keyField="id" onRowClick={this.handleRowClick}>
                <Column field="id" renderCell={assignRenderer} extraProps={{ setActiveRowClassName: this.setActiveRowClassName, setRowActive: this.setRowActive, registerScrollCallback: this.registerScrollCallback, closeParentDropdown: closeDropdown }} />
                <Column field="name">Name</Column>
                <Column field="description" renderCell={detailRenderer} />
                <Column field="totalCount">Total</Column>
                <Column field="deleteButtons" renderCell={deleteRenderer} extraProps={{ onClick: this.handleDelete }} />
              </Table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default dropdownable(connect(state => ({
  loading: selectSavedSearchLoading(state),
  items: selectSavedSearches(state),
}), {
  loadSearch,
  deleteSearch,
  confirm: Confirm.open,
})(AllSearches));
