import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pluralize from 'pluralize';
import { Map, List } from 'immutable';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import DropdownMenu from 'components/base/DropdownMenu';
import Button from 'components/base/Button';
import InputDate from 'components/base/InputDate';
import FormControlWrapper from 'components/base/FormControlWraper';

import { GroupTypeNames, GroupTypeHeaders } from 'data/property';
import SVG from 'components/base/SVG';
import { formatUTCDate } from 'utils/date/formatDate';
import { dragStartWrapper, dropWrapper } from 'utils/DOM/dragDrop';
import stopPropagation from 'utils/DOM/propagation';

import css from './style.scss';


const NAME_VIEW_MODE = ('Name');
const CREATE_DATE_VIEW_MODE = ('Date Created');
const UPDATE_DATE_VIEW_MODE = ('Date Modified');

const ViewModeList = [
  NAME_VIEW_MODE,
  CREATE_DATE_VIEW_MODE,
  UPDATE_DATE_VIEW_MODE,
];

const NodeTypes = {
  ROOT: 'ROOT',
  ITEM: 'ITEM',
  GROUP: 'GROUP',
  FOLDER: 'FOLDER',
};

const startOfDay = d => moment(d).startOf('day').valueOf();

const initFolders = (ar, parent) => {
  // Traverse hierarchy and add references & depth indicators (Assigning directly & disabling lint so that map references remain intact)
  ar.forEach((f) => {
    /* eslint-disable no-param-reassign */
    f.root = !parent ? this : parent.root;
    f.depth = !parent ? 0 : parent.depth + 1;
    f.parent = parent;
    /* eslint-enable no-param-reassign */

    initFolders(f.children, f);
  });
};

const getFolders = (allFolders) => {
  const folderMap = allFolders.reduce((map, f) => {
    const { name, key, parentKey } = f;

    return {
      ...map,
      [key]: {
        key,
        parentKey,
        name,
        type: NodeTypes.FOLDER,
        displayed: false,
        expanded: true,
        children: [],   // List of all children (items & sub-folders)
        // subFolders: [], // List of sub-folders
      },
    };
  }, {});

  // Build hierarchy of all folders
  const rootFolders = allFolders.reduce((rootFolders, f) => {
    const { key, parentKey } = f;
    const folder = folderMap[key];

    // Get appropriate folder array; either root or parent folder's children array.
    const folderList = !parentKey ? rootFolders : (folderMap[parentKey] || {}).children;

    // Should never occur, but ignore folders where no parent is found.
    if (folderList) folderList.push(folder);

    return rootFolders;
  }, []);

  initFolders(rootFolders);

  return {
    folderMap,
    rootFolders,
  };
};

const initNodes = (item, showAll) => {
  const { type, id, key } = item;

  // Filter out anything we don't want displayed. Currently this would just be empty folders if we're in search mode or non-standard view mode.
  const children = item.children.reduce((ar, i) => {
    if (showAll || i.type === NodeTypes.ITEM || i.children.length) ar.push(initNodes(i, showAll));
    return ar;
  }, []);

  // Sort based on type, then name
  children.sort((a, b) => {
    const { type: type1, sort: sort1, name: name1 } = a;
    const { type: type2, sort: sort2, name: name2 } = b;

    // List leaf items first, groups / folders second
    if (type1 !== type2) return type1 === NodeTypes.ITEM ? -1 : 1;

    // Sort values will be set for groups (currently only numeric date values), or "all" leaf items. Sort by that if present.
    if (sort1 && sort2 && sort1 !== sort2) return sort1 < sort2 ? -1 : 1;

    // Lastly, sort by item name
    return (name1 || '').localeCompare(name2 || '');
  });

  return {
    ...item,
    nodeKey: `${type}-${id || key || ''}`,
    children,
  };
};


class ToggleList extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleViewModeSelect = this.handleViewModeSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleClearCriteria = this.handleClearCriteria.bind(this);
    this.search = this.search.bind(this);

    this.refMap = {};
    this.state = {
      isOpen: this.props.isOpen,
      searching: false,
      viewMode: NAME_VIEW_MODE,
      dropTarget: undefined,
      groups: {},
      folderStatus: {},
      name: '',
      dateFrom: null,
      dateTo: null,
    };

    this.state.rootItem = this.search(props, false);
  }

  componentWillReceiveProps(props) {
    this.search(props);
  }

  handleClick(id) {
    const { history, match: { path } } = this.props;

    history.push(`${path}/${id}`);
  }

  handleSearchClick() {
    this.setState({ searching: !this.state.searching }, (() => {
      if (this.state.searching) {
        const { name } = this.refMap;
        name.focus();
        name.select();
      }

      this.search();
    }));
  }

  handleDrop() {
    const { dropTarget: { id } } = this.state;
    const { onGroupDrop, type } = this.props;

    if (id) onGroupDrop(Number(id) === -1 ? null : id, type);

    this.handleDragEnd();
  }

  handleChange(ev) {
    let { name, value } = ev.target || ev;    
    this.setState({ [name]: value , viewMode: value }, this.search);
  }

  handleRef(ref) {
    if (ref) this.refMap[ref.name] = ref;
  }

  handleDragStart(data) {
    const { onDragStart, type } = this.props;

    onDragStart({ ...data, groupType: type });
  }

  handleDragEnd() {
    this.setState({ dropTarget: undefined });
    this.props.onDragEnd();
  }

  handleDragOver(data, enter) {
    const { dragGroup: { id: groupId, size } } = this.props;
    const { id, nodeKey, type } = this.state.dropTarget || {};
    // const { id } = data;

    // { ..., [id]: !!(enter && id && id !== groupId && size) }
    let dropTarget;

    if (enter) {
      if (data.id && data.id !== groupId && size && (data.id === -1 || data.type === NodeTypes.ITEM)) dropTarget = data;
    } else if (data.nodeKey !== nodeKey) dropTarget = this.state.dropTarget;

  if(dropTarget) {
    this.setState({ dropTarget });
  }
  }

  handleViewModeSelect({ value }) {
    this.setState({ viewMode: value }, this.search);
  }

  handleGroupClick(id) {
    const { groups } = this.state;
    this.setState({ groups: { ...groups, [id]: id in groups && !groups[id] } });
  }

  handleClearCriteria() {
    this.setState({ name: '', dateFrom: null, dateTo: null }, this.search);
  }

  /**
   Search method is called regardless of whether data is being "searched" and is used to build the tree of data being displayed.
   Leaves will be the individual "loadable" items (property/contact groups, etc), and branches will be logical groupings of
   those leaves and other branches. Branches can be:

   - Root
   - Display mode grouping (Create date, Update Date, etc). This group is omitted in the default display mode.
   - Folders
   - Sub-Folders

   Special "all" items ("All Saved Properties", etc) will be displayed at the top (first leaves in the root branch), and not
   placed in any sub-groups. "All" items are identifiable by having an "id" property < 100.

   Items not contained in a folder will be displayed before any folders. Items in a folder will be displayed before any of
   its sub-folders. Empty folders should be included, but not expandable.

   - Root
     - All Saved Properties
     - Group 1
     - Group 2
     - Folder 1
       - Group 3
       - Group 4
       - Sub-Folder 1
         - Group 5
     - Folder 2
   */
  search(props = this.props, setState = true) {
    const { sizeProperty, data, folders, type } = props;
    const { viewMode, searching, name: searchName, dateFrom, dateTo } = this.state;

    // Build map of all folders / sub-folders
    const allFolders = (folders || List()).toJS();

    getFolders(allFolders);

    /*
     * Generate "root" object. It's children should be composed of:
     *  - List of "All" items. ("All Saved Properties")
     *  - List of groups. (E.g. grouping by list creation date) If in standard display mode, there will be a single group keyed by ''.
     *
     * The Groups will be composed of:
     *  - All filtered items within the group.
     *  - All folders.
     *
     * If we're filtering and/or in a non-standard list mode, we'll only display folders that contain matching items, indicated by the "displayed" flag.
     */
    const groupMap = {};
    const nameMode = viewMode === NAME_VIEW_MODE;

    const rootChildren = (data || List()).toJS().reduce((rootChildren, i) => {
      const { id, name, createDate, updateDate, folderKey } = i;

      const size = sizeProperty ? i[sizeProperty] : null;

      const item = {
        id,
        name,
        type: NodeTypes.ITEM,
        size: size && typeof size === 'object' ? size.size : size,
        sort: type === 'FAVORITE' && name === 'My Properties' ? 1 : 2,  // Pin My Properties at the top.
        children: [],
      };

      // Special "all" item ("All Saved Properties", etc) will have an ID < 100; add them directly to the root list
      if (id < 100) rootChildren.push(item);
      else {
        // Default ("Name") mode will only have one group, identified by ''. Only other supported modes are update date and create date, in which case they'll be ID'd by the respective date.
        let groupId = '';
        let searchDate = null;
        if (!nameMode) {
          searchDate = startOfDay(viewMode === UPDATE_DATE_VIEW_MODE ? updateDate : createDate);
          groupId = searchDate;
        }

        let group = groupMap[groupId];
        if (!group) {
          // Get a copy of the folder structure for each group. Folders can be repeated of we're in non-standard display mode, with items in the same folder scattered amongst different groups.
          const folders = getFolders(allFolders);

          group = {
            id: groupId,
            type: NodeTypes.GROUP,
            displayed: true,
            folders,
            name: groupId === '' ? '' : formatUTCDate(groupId),  // Currently the only groups will be date values
            sort: groupId === '' ? null : -groupId,   // Currently the only groups will be date values and should sort descending
            children: folders.rootFolders.slice(), // Add copy of root folders as initial children.
          };

          groupMap[groupId] = group;
          rootChildren.push(group);
        }

        // Process item if 1) search mode is disabled or 2) item matches search criteria
        if (!searching || (
          (searchName.trim() === '' || name.toLowerCase().includes(searchName.toLowerCase())) &&
          (!dateFrom || dateFrom <= searchDate) &&
          (!dateTo || dateTo >= searchDate)
        )) {
          // Add item under the respective folder if it's assigned to one, and directly in the group's children otherwise
          const { children, folders: { folderMap } } = group;
          const list = !folderKey ? children : (folderMap[folderKey] || {}).children;

          // Should never happen, but ignore item if parent folder is not found
          if (list) list.push(item);
        }
      }

      return rootChildren;
    }, []);

    const showAll = !searching && nameMode;

    const rootItem = initNodes({
      type: NodeTypes.ROOT,
      children: rootChildren,
    }, showAll);

    if (setState) this.setState({ rootItem });

    return rootItem;
  }

  render() {
    const { caption, children, selectedId, before, onAddClick, draggable, folderDraggable, dragGroupId, type, allowDrop, allowAddDrop, data, quantity, addItems, addTitle, onDeleteFolderClick, onEditFolderClick } = this.props;
    const { isOpen, searching, dropTarget: { nodeKey: dropKey, id: dropId } = {}, viewMode, groups, rootItem, name, dateFrom, dateTo } = this.state;
    const size = data.size - (Number(data.getIn([0, 'id'])) === 0 ? 1 : 0);
    const qty = typeof quantity === 'number' ? quantity : size;
    const dateProps = { onChange: this.handleChange, isClearable: true, showYearDropdown: true, dateFormat: 'MM/dd/yy' };

    const renderItem = (i) => {
      const { id, key, nodeKey, name, type, children, size } = i;
      const open = !(nodeKey in groups) || groups[nodeKey];
      const item = type === NodeTypes.ITEM;
      const folder = type === NodeTypes.FOLDER;
      const empty = !children.length;
      const dragData = { id, name, key, type, nodeKey, size };

      const dragProps = {
        onDragStart: dragStartWrapper(() => this.handleDragStart(dragData)),
        onDragEnd: this.handleDragEnd,
        onDragEnter: allowDrop ? (() => this.handleDragOver(dragData, true)) : undefined,
        onDragLeave: allowDrop ? (() => this.handleDragOver(dragData, false)) : undefined,
        onDrop: allowDrop ? dropWrapper(this.handleDrop) : undefined,
        onDragOver: allowDrop ? (ev => ev.preventDefault()) : undefined,
      };

      return (
        <div
          key={nodeKey}
          className={classNames(css.node, css[type.toLowerCase()])}
        >
          <div className={css.nodeLiner} />
          <div className={css.nodeMask} />
          {item || !name ? null : (
            <div
              className={classNames(css.header, css[`${type.toLowerCase()}Header`], { [css.open]: open, [css.empty]: empty })}
              onClick={() => this.handleGroupClick(nodeKey)}
              draggable={folderDraggable}
              {...dragProps}
            >
              {folder ? <SVG icon={`iconFolder${open && !empty ? 'Open' : 'Closed'}`} /> : <SVG icon="iconExpand" className={css.iconCaret} />}
              <div className={css.label}>{name}</div>
              <div className={css.actions} onClick={stopPropagation}>
                {!folder || !onEditFolderClick ? null : <SVG icon="iconEdit" title="Edit Folder" onClick={() => onEditFolderClick(key)} />}
                {!folder || !onDeleteFolderClick ? null : <SVG icon="iconTrash" title="Delete Folder" onClick={() => onDeleteFolderClick(key)} />}
              </div>
            </div>
          )}
          {!item ? null : (
            <div
              className={classNames(css.itemInner, {
                [css.selected]: String(id) === String(selectedId),
                [css.dropping]: dropKey === nodeKey,
                [css.noDrop]: !id || dragGroupId === id,
              })}
              draggable={!!(draggable && id)}
              onClick={() => this.handleClick(id)}
              {...dragProps}
            >
              {id !== 2 ? null : <SVG icon="iconHeart" className={css.heart} />}
              <div className={css.label}>
                <div className={css.labelName}>{name}</div>
                {isNaN(size) || size == null ? null : <div className={css.labelQuantity}>({size})</div>}
              </div>
            </div>
          )}
          {!open || !children || !children.length ? null : (
            <div className={css.childContainer}>
              <div className={css.childLiner} />
              {children.map(i => renderItem(i))}
            </div>
          )}
        </div>
      );
    };

    return (
      <div className={classNames(css.section, { [css.open]: isOpen, [css.searchMode]: searching })}>
        <div
          className={classNames(css.header, { [css.dropping]: dropId === -1 })}
          onDragEnter={allowAddDrop ? (() => this.handleDragOver({ id: -1 }, true)) : null}
          onDragLeave={allowAddDrop ? (() => this.handleDragOver({ id: -1 }, false)) : null}
          onDragOver={allowAddDrop ? (ev => ev.preventDefault()) : null}
          onDrop={allowAddDrop ? dropWrapper(this.handleDrop) : null}
        >
          <button className={css.toggle} onClick={() => this.setState({ isOpen: !isOpen })}>
            <SVG icon={isOpen ? 'iconCaretDown' : 'iconCaretRight'} />
          </button>
          <div className={css.name}>
            {caption || pluralize(GroupTypeHeaders[type])}
            {!qty ? null : <div className={css.size}>{qty}</div>}
          </div>
          <div className={css.actions}>
            {!size ? null : <SVG icon="iconSearch" onClick={this.handleSearchClick} className={classNames(css.iconSearch, { [css.active]: searching })} title="Search" />}
            {!size ? null : <DropdownMenu icon="iconList" items={ViewModeList.map(m => ({ value: m, label: m.description }))} selection={viewMode} onSelect={this.handleViewModeSelect} title="View Type" caption="View Type" />}
            {!onAddClick ? null : <SVG icon="iconPlus" onClick={() => onAddClick(type)} title={addTitle || `Add ${GroupTypeNames[type]}`} />}
            {!addItems || !addItems.length ? null : <DropdownMenu icon="iconPlus" items={addItems.map(i => ({ value: i, label: i.label, onClick: i.onClick }))} onSelect={i => i.onClick()} title="Add Item" caption="Add Item" />}
          </div>
        </div>
        <div className={css.search}>
          <div className={css.row}>
            <div className={css.label}>Search Name:</div>
            <FormControlWrapper>
              <input type="text" value={name} name="name" onChange={this.handleChange} autoComplete="off" ref={this.handleRef} />
            </FormControlWrapper>
          </div>
          <div className={css.row}>
            <div className={css.label}>Search Date:</div>
            <div className={css.range}>
              <FormControlWrapper>
                <InputDate {...dateProps} value={dateFrom} name="dateFrom" />
              </FormControlWrapper>
              <div className={css.sep}>-</div>
              <FormControlWrapper className="clsSearchDateTo">
                <InputDate {...dateProps} value={dateTo} name="dateTo" />
              </FormControlWrapper>
            </div>
          </div>
          <div className={css.clear}>
            <Button kind={Button.kind.grayGhost} size={Button.size.small} onClick={this.handleClearCriteria}>Clear</Button>
          </div>
        </div>
        <div className={css.body}>
          {before}
          {!children ? null : <div className={css.children}>{children}</div>}
          {!rootItem ? null : renderItem(rootItem)}
        </div>
      </div>
    );
  }
}


ToggleList.propTypes = {
  caption: PropTypes.string,
  type: PropTypes.string,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAddClick: PropTypes.func,
  onGroupDrop: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  draggable: PropTypes.bool,
  folderDraggable: PropTypes.bool,
  allowDrop: PropTypes.bool,
  allowAddDrop: PropTypes.bool,
  quantity: PropTypes.number,
  onDeleteFolderClick: PropTypes.func,
  onEditFolderClick: PropTypes.func,
  dragGroup: PropTypes.instanceOf(Map),
  data: PropTypes.instanceOf(List).isRequired,
  folders: PropTypes.instanceOf(List).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  addItems: PropTypes.array,
};

ToggleList.defaultProps = {
  before: null,
  isOpen: true,
  draggable: false,
  folderDraggable: false,
  allowDrop: false,
  allowAddDrop: false,
  folders: List(),
};


export default withRouter(ToggleList);
