import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import SVG from 'components/base/SVG';
import Modal from 'components/base/Modal';
import { Link } from 'react-router-dom';
import Button, { SolidButton } from 'components/Button';
import Checkbox from 'components/base/Checkbox';
import { getPopupRegistration, closePopup, openPopup, Priority } from 'app/PopupHolder';
import { savePostcardCampaign, selectLoading, selectPostcardCampaign, MarketingCampaignStatuses, selectPostcardRates } from 'data/campaigns';
import Image from 'components/base/Image';
import CheckoutSummary from 'app/components/CheckoutSummary';
import Confirm from 'app/components/Confirm';
import { validate } from 'utils/validation';

import css from './style.scss';


class PostcardCheckout extends PureComponent {
  constructor(props) {
    super(props);

    this.handlePurchase = this.handlePurchase.bind(this);
    this.handleSummaryLoad = this.handleSummaryLoad.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = { checks: [false, false, false], campaign: props.campaign, message: null };
  }

  handlePurchase() {
    const { checks, campaign } = this.state;
    const { confirm, savePostcardCampaign, closePopup } = this.props;

    if (checks.findIndex(c => !c) >= 0) confirm({ cancelLabel: null, question: 'Please review your order and check all boxes.' });
    else {
      confirm({
        caption: 'Confirm Publish',
        okLabel: 'Publish',
        question: 'Publish campaign? Once published the postcard campaign will be submitted for print and its settings & recipients cannot be changed.',
        onOk: () => {
          validate(() => {
            const { paymentMethodId, cardCode, paymentAmount, creditAmount } = this.summaryApi.validate();

            savePostcardCampaign({ ...campaign, status: MarketingCampaignStatuses.PROCESSING }, false, paymentMethodId, cardCode, paymentAmount, creditAmount, () => {
              const { success, message } = this.props.campaign;

              if (success) {
                setTimeout(() => {
                  closePopup();
                  confirm({ cancelLabel: null, question: 'Congratulations! Your order was submitted successfully and will be processed within 3-5 business days.' });
                }, 1);
              } else this.setState({ message });
            });
          });
        },
      });
    }
  }

  handleSummaryLoad(api) {
    this.summaryApi = api;
  }

  handleChange(ev) {
    const { name, checked } = ev.target || ev;
    const checks = this.state.checks.slice();
    checks[Number(name)] = checked;

    this.setState({ checks });
  }

  render() {
    const { loading, closePopup, rates } = this.props;
    const { checks, message, campaign: { lines } } = this.state;
    const btnProps = { loading };
    const businessAddressError = message === 'ERROR_BUSINESS_ADDRESS';

    const lines2 = lines.slice();
    lines2.push({ ...lines[0], description: 'Total' });

    // TODO: Move below code to common area and out of render (same logic used for skip tracing)
    let appliedRate = {};
    const line = (lines && lines[0]) || {};
    const { quantity } = line;
    rates.map((r, i) => {
      const rate = { ...r, quantityMin: i ? (rates[i - 1].quantity + 1) : 1, quantityMax: r.quantity };

      if (quantity >= rate.quantityMin && quantity <= (rate.quantity || quantity)) appliedRate = { ...rate, ...line };

      return rate;
    });

// { amount: rate, quantityMin: 1, quantityMax: 500 }
    return (
      <Modal isOpen uniqId="PostcardCheckout" width="auto" padding="35px 30px">
        <div className={css.checkout}>
          <div className={css.header}>Finalize Order</div>
          <div className={css.body}>
            <div className={css.left}>
              <Image src={this.props.campaign.frontGraphic.rendering?.dataUrl} alt="front side" />
              <Image src={this.props.campaign.backGraphic.rendering?.dataUrl} alt="back side" />
            </div>
            <div className={css.right}>
              <div className={css.content}>
                <CheckoutSummary
                  lines={lines2}
                  rate={appliedRate}
                  instructions="Please review your postcard renderings and click confirm to proceed with your order."
                  onLoad={this.handleSummaryLoad}
                />
                <div className={css.important}>
                  <div>
                    <SVG icon="iconWarning" />
                    Important
                  </div>
                  <div>
                    Finalize your postcard proof by completing the checklist below.<br />
                    ALL checkboxes are required in order to process your order.
                  </div>
                </div>
                <div className={css.checklist}>
                  <Checkbox checked={checks[0]} name="0" onClick={this.handleChange} label="Are your text, fonts and images within the specified margins?" />
                  <Checkbox checked={checks[1]} name="1" onClick={this.handleChange} label="Did you include your contact information?" />
                  <Checkbox checked={checks[2]} name="2" onClick={this.handleChange} label="Are your images clear and high resolution enough for printing?" />
                </div>
                <div className={css.instructions}>
                  <div>If you are satisfied with your design please press &quot;place order.&quot; To make changes, click cancel and return to the editor. By clicking place order, your understand no changes can be made after your order is placed.</div>
                  <div>*Note: Orders are typically processed within 3-5 business days and sent to USPS for distribution.</div>
                </div>
              </div>
              {!message || businessAddressError ? null : <div className={css.message}>{message}</div>}
              {!businessAddressError ? null : <div className={css.message}>ERROR - Business Name &amp; Address Required. A valid business address &amp; business name are required to send mail. <Link to="/account" onClick={closePopup}>Click here</Link> to update the business information on file.</div>}
              <div className={css.buttons}>
                <Button {...btnProps} onClick={closePopup}>Cancel</Button>
                <SolidButton {...btnProps} onClick={this.handlePurchase}>Place Order</SolidButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const PostcardCheckoutPopup = connect(state => ({
  loading: selectLoading(state),
  campaign: selectPostcardCampaign(state).toJS(),
  rates: selectPostcardRates(state).toJS(),
}), {
  savePostcardCampaign,
  confirm: Confirm.open,
})(PostcardCheckout);

const registrationId = getPopupRegistration(PostcardCheckoutPopup);
PostcardCheckoutPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
PostcardCheckoutPopup.close = () => closePopup({ popup: registrationId });

export default PostcardCheckoutPopup;
