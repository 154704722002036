import Account from "./Account";
import App from "./App";
import AuthGuard from "./AuthGuard";
import Campaigns from "./Campaigns";
import CampaignEditor from "./Campaigns/CampaignEditor";
import Dashboard from "./Campaigns/Dashboard";
import ActivityPopup from "./Campaigns/Dashboard/ActivityPopup";
import CampaignCreated from "./Campaigns/Dashboard/CampaignCreated";
import Email from "./Campaigns/Email";
import EmailSettings from "./Campaigns/Email/Settings";
import NoCampaigns from "./Campaigns/NoCampaigns";
import Postcard from "./Campaigns/Postcard";
import Voicemail from "./Campaigns/Voicemail";
import Website from "./Campaigns/Website";
import WebsiteSettings from "./Campaigns/Website/Settings";
import Contacts from "./Contacts";
import ContactEditor from "./Contacts/ContactEditor";
import DeferredOnLocation from "./DeferredOnLocation";
import Analysis from "./Property/Analysis";
import PropertyDetail from "./Property/Detail";
import UrlStorage from "./Property/Detail/UrlStorage";
import PropertyGallery from "./Property/Gallery";
import PropertyGroup from "./Property/Group";
import OfferWizard from "./Property/Offer";
import SiteMap from "./SiteMap";
import SvgHost from "./SvgHost";
import AduCalculator from "./components/AduCalculator";
import Components from "./components/Components";
import FormExample from "./components/FormExample";
import Image from "./components/Image";
import Loadable from "./components/Loadable";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

const getId = (obj) => (isNaN(obj) ? obj.get("id") : obj);
const getPath = (root, path) =>
  `${root}${path || path === 0 ? `/${path}` : ""}`;
export const propertyGroupPath = (path) => getPath("/property/group", path);
export const contactGroupPath = (path) => getPath("/contact", path);
export const campaignPath = (path) => getPath("/campaign", path);
export const campaignWebsitePath = (campaign, path) =>
  campaignPath(`${getId(campaign)}/website${path ? `/${path}` : ""}`);
export const campaignEmailPath = (campaign, path) =>
  campaignPath(`${getId(campaign)}/email${path ? `/${path}` : ""}`);
export const postcardPath = (campaign, id, path) =>
  campaignPath(`${getId(campaign)}/postcard/${id}${path ? `/${path}` : ""}`);

const propertyRoutes = (
  <Switch>
    <Route path="/offer">
      <OfferWizard>
        <Switch>
          <Route path="/arv" />
          <Route path="/exit" />
          <Route path="/max" />
          <Route path="/goal" />
          <Route path="/finish" />
        </Switch>
      </OfferWizard>
      {/* <Redirect from="/offer" to="/arv" /> */}
      <Route exact path="/offer" render={() => <Redirect to="/arv" />} />
    </Route>
  </Switch>
);

const routes = (
  <Switch>
    <SvgHost>
      <DeferredOnLocation>
        <AuthGuard>
          <App>
            <Route path="/account/profile">
              <Account />
            </Route>
            <Route path="/account/team">
              <Account />
            </Route>
            <Route path="/account/notifications">
              <Account />
            </Route>
            <Route path="/account/activity">
              <Account />
            </Route>
            <Route path="/account/services">
              <Account />
            </Route>

            <Route path="/search/:id/offer/arv">
              <UrlStorage>
                <PropertyDetail><OfferWizard /></PropertyDetail>
              </UrlStorage>
            </Route>
            <Route path="/search/:id/offer/exit">
              <UrlStorage>
                <PropertyDetail><OfferWizard /></PropertyDetail>
              </UrlStorage>
            </Route>
            <Route path="/search/:id/offer/max">
              <UrlStorage>
                <PropertyDetail><OfferWizard /></PropertyDetail>
              </UrlStorage>
            </Route>
            <Route path="/search/:id/offer/goal">
              <UrlStorage>
                <PropertyDetail><OfferWizard /></PropertyDetail>
              </UrlStorage>
            </Route>
            <Route path="/search/:id/offer/finish">
              <UrlStorage>
                <PropertyDetail><OfferWizard /></PropertyDetail>
              </UrlStorage>
            </Route>
             {/* <Redirect from="search/:id/offer" to="/search/:id/offer/arv" /> */}
            <Route exact path="/search/:id/offer" render={() => <Redirect to="/search/:id/offer/arv" />} />


            <Route path="/search/:id/analysis/:type">
              <UrlStorage>
                <PropertyDetail>
                  <Analysis />
                </PropertyDetail>
              </UrlStorage>
            </Route>
            {/* <Redirect from="/search/:id/analysis" to="/search/:id/analysis/purchase" /> */}
            <Route exact path="/search/:id/analysis" render={() => <Redirect to="/search/:id/analysis/purchase" />} />


            <Route path="/search/:id/gallery">
              <UrlStorage>
                <PropertyDetail><PropertyGallery /></PropertyDetail>
              </UrlStorage>
            </Route>
            <Route path="/search/:id/adu">
              <UrlStorage>
                <PropertyDetail><AduCalculator /></PropertyDetail>
              </UrlStorage>
            </Route>
            <Route path="/search/:id">
              <UrlStorage>
                <PropertyDetail>{propertyRoutes}</PropertyDetail>
              </UrlStorage>
            </Route>
            <Route path="/search">
              <UrlStorage>
                <PropertyDetail />
              </UrlStorage>
            </Route>


            <Route path="/property/group/:groupId/:id/offer/arv">
              <UrlStorage>
                <PropertyGroup>
                <PropertyDetail><OfferWizard /></PropertyDetail>
                </PropertyGroup>
              </UrlStorage>
            </Route>
            <Route path="/property/group/:groupId/:id/offer/exit">
              <UrlStorage>
                <PropertyGroup>
                <PropertyDetail><OfferWizard /></PropertyDetail>
                </PropertyGroup>
              </UrlStorage>
            </Route>
            <Route path="/property/group/:groupId/:id/offer/max">
              <UrlStorage>
                <PropertyGroup>
                <PropertyDetail><OfferWizard /></PropertyDetail>
                </PropertyGroup>
              </UrlStorage>
            </Route>
            <Route path="/property/group/:groupId/:id/offer/goal">
              <UrlStorage>
                <PropertyGroup>
                <PropertyDetail><OfferWizard /></PropertyDetail>
                </PropertyGroup>
              </UrlStorage>
            </Route>
            <Route path="/property/group/:groupId/:id/offer/finish">
              <UrlStorage>
                <PropertyGroup>
                <PropertyDetail><OfferWizard /></PropertyDetail>
                </PropertyGroup>
              </UrlStorage>
            </Route>
            {/* <Redirect from="property/group/:groupId/:id/offer" to="/property/group/:groupId/:id/offer/arv" /> */}
            <Route exact path="/property/group/:groupId/:id/offer" render={() => <Redirect to="/property/group/:groupId/:id/offer/arv" />} />


            <Route path="/property/group/:groupId/:id/analysis/:type">
              <UrlStorage>
                <PropertyDetail>
                  <Analysis />
                </PropertyDetail>
              </UrlStorage>
            </Route>
            {/* <Redirect from="/property/group/:groupId/:id/analysis" to="/property/group/:groupId/:id/analysis/purchase" /> */}
            <Route exact path="/property/group/:groupId/:id/analysis" render={() => <Redirect to="/property/group/:groupId/:id/analysis/purchase" />} />
                        

            <Route path="/property/group/:groupId/:id/gallery">
              <UrlStorage>
              <PropertyGroup>

                <PropertyDetail><PropertyGallery /></PropertyDetail>
                </PropertyGroup>
              </UrlStorage>
            </Route>
            <Route path="/property/group/:groupId/:id/adu">
              <UrlStorage>
                <PropertyDetail><AduCalculator /></PropertyDetail>
              </UrlStorage>
            </Route>
            <Route path="/property/group/:groupId/:id">
              <UrlStorage>
                <PropertyGroup>
                    <PropertyDetail>{propertyRoutes}</PropertyDetail>
                </PropertyGroup>
              </UrlStorage>
            </Route>
            <Route path="/property/group/:groupId">
              <UrlStorage>
                <PropertyGroup>
                    <PropertyDetail>{propertyRoutes}</PropertyDetail>
                </PropertyGroup>
              </UrlStorage>
            </Route>
            <Route path="/property/group" >
              <UrlStorage>
                <PropertyGroup />
              </UrlStorage>
            </Route>

            <Route path="/campaign">
              <Campaigns />
            </Route>
            <Route path="/campaign/new">
              <Campaigns>
                <CampaignEditor />
              </Campaigns>
            </Route>
            <Route path="/campaign/:campaignId">
              <Campaigns>
              </Campaigns>
            </Route>
            {/* <Route path="/campaign/:campaignId">
              <Campaigns>
              </Campaigns>
            </Route> */}
            <Route path="/campaign/:campaignId/postcard">
              <Campaigns>
              </Campaigns>
            </Route>
            <Route path="/campaign/:campaignId/postcard/:postcardId">
              <Campaigns>
              </Campaigns>
            </Route>
            <Route path="/campaign/:campaignId/postcard/:postcardId/:mode">
              <Campaigns>
              </Campaigns>
            </Route>
            <Route path="/campaign/:campaignId/created">
              <Campaigns>
                <Dashboard>
                  <CampaignCreated />
                </Dashboard>
              </Campaigns>
            </Route>
            <Route path="/campaign/:campaignId/activity">
              <Campaigns>
              </Campaigns>
            </Route>
            {/* <Redirect from="/campaign" to="/campaign/new" /> */}
            <Route exact path="/campaign" render={() => <Redirect to="/campaign" />} />

     
            <Route exact path="/contact/:groupId/new" component={Contacts} />
        <Route exact path="/contact/:groupId/:contactId" component={Contacts} />
        <Route exact path="/contact/:groupId" component={Contacts} />
        <Route path="/contact" component={Contacts} />


            {/* <Route exact path="/contact/:groupId/new">
              <Contacts />
            </Route>
            <Route exact path="/contact/:groupId/:contactId">
              <Contacts />
            </Route>
            <Route exact path="/contact/:groupId">
              <Contacts />
            </Route>
            <Route path="/contact">
              <Contacts />
            </Route> */}
            
            <Route path="/components/image" component={Image} />
            <Route path="/components/loadable" component={Loadable} />
            <Route path="/components/form" component={FormExample} />
            <Route path="/components" component={Components} />

            <Route path="/site-map" component={SiteMap} />

            {/* <Redirect from="/" to="/search" /> */}
            <Route exact path="/" render={() => <Redirect to="/search" />} />
          </App>
        </AuthGuard>
      </DeferredOnLocation>
    </SvgHost>
  </Switch>
);

export default routes;