import { createSelector } from 'reselect';

import { FAVORITE_SEARCH_SLOTS } from './constants';


const FavoriteSlots = Array.from({ length: FAVORITE_SEARCH_SLOTS }, (v, i) => i + 1);


const checkState = state => state;

const selectSearch = createSelector(checkState, state => state.get('search'));

export const selectLoading = createSelector(selectSearch, search => search.get('loading'));

export const selectStale = createSelector(selectSearch, search => search.get('stale'));

export const selectSuggestion = createSelector(selectSearch, search => search.get('suggestion'));

export const selectAvailableListingTypes = createSelector(selectSearch, search => search.get('availableListingTypes'));

export const selectSuggestionValue = createSelector(selectSuggestion, suggestion => suggestion.get('value'));

export const selectLocation = createSelector(selectSearch, search => search.get('location'));

export const selectFilter = createSelector(selectSearch, search => search.get('filter'));

export const selectNewFilter = createSelector(
  selectSearch,
  search => search.get('filter').toJS() // Convert to plain JS object
);

export const selectFilterByKey = createSelector(
  selectFilter,
  (_, key) => key, // Pass the key as the second argument
  (filter, key) => {
    if (key) {
      return { key, value: filter.get(key) };
    } 
  }
);


export const selectResult = createSelector(selectSearch, search => search.get('result'));

export const selectQuery = createSelector(selectResult, search => search.get('query'));

// Define a selector that selects the 'localFilter' from the 'search' slice of state
export const selectLocalFilter = createSelector(
    selectSearch,
    search =>  search.get('localFilter')
  );
  
// Define a selector that filters 'localFilter' by ID
export const selectLocalFilterById = createSelector(
    selectLocalFilter,
    (_, id) => id,
    (localFilter, id) => {
      // Filter 'localFilter' by the provided ID
      return localFilter && localFilter.length && localFilter.filter(item => item?.key === id);
    }
  );

export const selectActiveContext = createSelector(selectResult, result => result.getIn(['contexts', result.get('activeContext')], null));

export const selectMap = createSelector(selectSearch, search => search.get('map'));

export const selectMapMetrics = createSelector(selectMap, map => map.get('metrics'));

export const selectStatistic = createSelector(selectSearch, map => map.get('statistic'));

export const selectSavedSearches = createSelector(selectSearch, map => map.get('savedSearches'));

export const selectSavedSearchLoading = createSelector(selectSearch, map => map.get('savedSearchLoading'));

export const selectFavoriteSearches = createSelector(selectSearch, map => map.get('favoriteSearches'));

export const selectFavoriteSearchCounts = createSelector(selectSearch, map => map.get('favoriteSearchCounts'));

export const selectFavoriteSearchLoading = createSelector(selectSearch, map => map.get('favoriteSearchLoading'));

export const selectFavoriteSearchStale = createSelector(selectSearch, map => map.get('favoriteSearchStale'));

export const selectFavoriteSearchSlots = createSelector([selectFavoriteSearches, selectFavoriteSearchCounts], (favoriteSearches, favoriteCounts) => FavoriteSlots.map(s => ({ slot: s, search: favoriteSearches.get(String(s)) })).map(s => ({ ...s, quantity: favoriteCounts.get(String(s.search && s.search.get('id')), 0) })));

export const selectFavoriteSearchSlotOptions = createSelector(selectFavoriteSearchSlots, slots => slots.map(s => ({ value: s.slot, ...(s.search ? { id: s.search.get('id'), label: s.search.get('name') } : { id: null, label: '-Empty-' }) })));
export const selectShowAllEditOption = createSelector(selectSearch, map => map.get('showAllEditOption'));

export const selectLeadList = createSelector(selectSearch, map => map.get('leadeList'));

export const selectSelectedsavesearch = createSelector(selectSearch, filter => filter.get('selectedsavesearch'));



export default selectSearch;
