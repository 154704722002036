import { CALL_API } from 'store/middleware/api';
import { joinPath } from 'utils/string';


export const CLEAR_ALL = ('CLEAR_ALL');
export const REFRESH_SEARCH = ('REFRESH_SEARCH');
export const SET_LOCATION = ('SET_LOCATION');
export const SET_SORT = ('SET_SORT');
export const SET_PAGE = ('SET_PAGE');
export const SET_RANGE = ('SET_RANGE');
export const SET_OVERLAY = ('SET_OVERLAY');
export const SET_LAYOUT = ('SET_LAYOUT');
export const SET_ACTIVE_CONTEXT = ('SET_ACTIVE_CONTEXT');
export const TOGGLE_RANGE = ('TOGGLE_RANGE');
export const TOGGLE_SELECTION = ('TOGGLE_SELECTION');

export const CLEAR_FILTER = ('CLEAR_FILTER');
export const UPDATE_FILTER = ('UPDATE_FILTER');
export const LOAD_SEARCH = ('LOAD_SEARCH');
export const REMOVE_FILTER_BY_KEY = ('REMOVE_FILTER_BY_KEY');

export const SEARCH_LISTING = ('SEARCH_LISTING');
export const SEARCH_LISTING_SUCCESS = ('SEARCH_LISTING_SUCCESS');
export const SEARCH_LISTING_ERROR = ('SEARCH_LISTING_ERROR');

export const SAVE_LISTINGS = ('SAVE_LISTINGS');
export const SAVE_LISTINGS_SUCCESS = ('SAVE_LISTINGS_SUCCESS');
export const SAVE_LISTINGS_ERROR = ('SAVE_LISTINGS_ERROR');

export const SEARCH_PROPERTY = ('SEARCH_PROPERTY');
export const SEARCH_PROPERTY_SUCCESS = ('SEARCH_PROPERTY_SUCCESS');
export const SEARCH_PROPERTY_ERROR = ('SEARCH_PROPERTY_ERROR');

export const CLEAR_SUGGESTION = ('CLEAR_SUGGESTION');
export const SET_SUGGESTION_LABEL = ('SET_SUGGESTION_LABEL');
export const SET_SUGGESTION_VALUE = ('SET_SUGGESTION_VALUE');
export const SET_SUGGESTIONS = ('SET_SUGGESTIONS');
export const SET_RECENT_SUGGESTIONS = ('SET_RECENT_SUGGESTIONS');
export const SEARCH_SUGGESTIONS = ('SEARCH_SUGGESTIONS');
export const SEARCH_SUGGESTIONS_SUCCESS = ('SEARCH_SUGGESTIONS_SUCCESS');
export const SEARCH_SUGGESTIONS_ERROR = ('SEARCH_SUGGESTIONS_ERROR');

export const SEARCH_PARCEL = ('SEARCH_PARCEL');
export const SEARCH_PARCEL_SUCCESS = ('SEARCH_PARCEL_SUCCESS');
export const SEARCH_PARCEL_ERROR = ('SEARCH_PARCEL_ERROR');

export const GET_MAP_METRICS = ('GET_MAP_METRICS');
export const GET_MAP_METRICS_SUCCESS = ('GET_MAP_METRICS_SUCCESS');
export const GET_MAP_METRICS_ERROR = ('GET_MAP_METRICS_ERROR');

export const SEARCH_STATISTICS = ('SEARCH_STATISTICS');
export const SEARCH_STATISTICS_SUCCESS = ('SEARCH_STATISTICS_SUCCESS');
export const SEARCH_STATISTICS_ERROR = ('SEARCH_STATISTICS_ERROR');

export const EXPORT_STATISTICS = ('EXPORT_STATISTICS');
export const EXPORT_STATISTICS_SUCCESS = ('EXPORT_STATISTICS_SUCCESS');
export const EXPORT_STATISTICS_ERROR = ('EXPORT_STATISTICS_ERROR');

export const CLEAR_STATISTICS = ('CLEAR_STATISTICS');
export const UPDATE_STATISTICS_FILTER = ('UPDATE_STATISTICS_FILTER');

export const SEARCH_FAVORITES = ('SEARCH_FAVORITES');
export const SEARCH_FAVORITES_SUCCESS = ('SEARCH_FAVORITES_SUCCESS');
export const SEARCH_FAVORITES_ERROR = ('SEARCH_FAVORITES_ERROR');

export const GET_SAVED_SEARCHES = ('GET_SAVED_SEARCHES');
export const GET_SAVED_SEARCHES_SUCCESS = ('GET_SAVED_SEARCHES_SUCCESS');
export const GET_SAVED_SEARCHES_ERROR = ('GET_SAVED_SEARCHES_ERROR');

export const SAVE_SEARCH = ('SAVE_SEARCH');
export const SAVE_SEARCH_SUCCESS = ('SAVE_SEARCH_SUCCESS');
export const SAVE_SEARCH_ERROR = ('SAVE_SEARCH_ERROR');

export const DELETE_SEARCH = ('DELETE_SEARCH');
export const DELETE_SEARCH_SUCCESS = ('DELETE_SEARCH_SUCCESS');
export const DELETE_SEARCH_ERROR = ('DELETE_SEARCH_ERROR');

export const ASSIGN_SEARCH_SLOT = ('ASSIGN_SEARCH_SLOT');
export const ASSIGN_SEARCH_SLOT_SUCCESS = ('ASSIGN_SEARCH_SLOT_SUCCESS');
export const ASSIGN_SEARCH_SLOT_ERROR = ('ASSIGN_SEARCH_SLOT_ERROR');

export const LOCAL_CLEAR_FILTER = ('LOCAL_CLEAR_FILTER');
export const LOCAL_UPDATE_FILTER = ('LOCAL_UPDATE_FILTER');
export const LOCAL_REMOVE_FILTER = ('LOCAL_REMOVE_FILTER');
export const LOCAL_LOAD_SEARCH = ('LOCAL_LOAD_SEARCH');

export const FILTER_SHOW_ALL_EDIT = ('FILTER_SHOW_ALL_EDIT');
export const FILTER_SHOW_ALL_CLEAR = ('FILTER_SHOW_ALL_CLEAR');

export const LEAD_LIST = ('LEAD_LIST');
export const LEAD_LIST_SUCCESS = ('LEAD_LIST_SUCCESS');
export const LEAD_LIST_ERROR = ('LEAD_LIST_ERROR');
export const LEAD_LIST_CLEAR = ('LEAD_LIST_CLEAR');

export const FILTER_SAVE_SEARCH = ('FILTER_SAVE_SEARCH');

export const FILTER_LANDUSE_CODE_DROPDOWN  = ('FILTER_LANDUSE_CODE_DROPDOWN');


const request = (path, method, startType, successType, errorType, body, { afterSuccess, afterError, params, successParams, download = false, query, skipGlobalErrorHandler } = {}) => ({
  [CALL_API]: {
    method,
    startType,
    successType,
    errorType,
    body,
    afterSuccess,
    afterError,
    params,
    successParams,
    query,
    download,
    skipGlobalErrorHandler,
    dislodging: true,
    path: joinPath('/resource/auth/ps4', path),
    // ...(method === 'post' && !isFormData(body) ? { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } } : {}),
  },
});


export const clearAll = () => ({ type: CLEAR_ALL });

export const refreshSearch = () => ({ type: REFRESH_SEARCH });

export const setLocation = location => ({ type: SET_LOCATION, location });

export const setOverlay = overlay => ({ type: SET_OVERLAY, overlay });

export const setLayout = layout => ({ type: SET_LAYOUT, layout });

export const setSort = (field, ascending) => ({ type: SET_SORT, field, ascending });

export const setPage = page => ({ type: SET_PAGE, page });

export const setRange = (rangeFrom, rangeTo) => ({ type: SET_RANGE, rangeFrom, rangeTo });

export const toggleRange = enabled => ({ type: TOGGLE_RANGE, enabled });

export const toggleSelection = id => ({ type: TOGGLE_SELECTION, id });

export const setActiveContext = context => ({ type: SET_ACTIVE_CONTEXT, context });

export const searchListings = query => request('listing', 'get', SEARCH_LISTING, SEARCH_LISTING_SUCCESS, SEARCH_LISTING_ERROR, null, { query });

export const saveListings = (query, selection, selectionInversed, groupType, groupName, afterSuccess) => request('user/listings', 'post', SAVE_LISTINGS, SAVE_LISTINGS_SUCCESS, SAVE_LISTINGS_ERROR, { ...query, selection, selectionInversed }, { query: { groupType, groupName }, afterSuccess });

export const searchProperty = query => request('property', 'get', SEARCH_PROPERTY, SEARCH_PROPERTY_SUCCESS, SEARCH_PROPERTY_ERROR, null, { query });

export const clearSuggestion = () => ({ type: CLEAR_SUGGESTION });

export const setSuggestionLabel = label => ({ type: SET_SUGGESTION_LABEL, label });

export const setSuggestionValue = value => ({ type: SET_SUGGESTION_VALUE, value });

export const setSuggestions = items => ({ type: SET_SUGGESTIONS, items });

export const setRecentSuggestions = (recentItems, clear = false) => ({ type: SET_RECENT_SUGGESTIONS, recentItems, clear });

export const searchSuggestions = q => request('property/suggestionsnew', 'get', SEARCH_SUGGESTIONS, SEARCH_SUGGESTIONS_SUCCESS, SEARCH_SUGGESTIONS_ERROR, null, { query: { q }, successParams: { query: q } });

export const clearFilter = () => ({ type: CLEAR_FILTER });

export const updateFilter = data => ({ type: UPDATE_FILTER, data });

export const removeFilterById = (id) => ({ type: REMOVE_FILTER_BY_KEY, id });

export const loadSearch = search => ({ type: LOAD_SEARCH, search });


export const searchParcel = (latitude, longitude, afterSuccess) => request(`map/parcels/${latitude}/${longitude}/property`, 'get', SEARCH_PARCEL, SEARCH_PARCEL_SUCCESS, SEARCH_PARCEL_ERROR, null, { afterSuccess });

export const getMapMetrics = () => request('map/statistics/metrics', 'get', GET_MAP_METRICS, GET_MAP_METRICS_SUCCESS, GET_MAP_METRICS_ERROR);


export const clearStatistics = () => ({ type: CLEAR_STATISTICS });

export const updateStatisticsFilter = data => ({ type: UPDATE_STATISTICS_FILTER, data });

export const searchStatistics = (location, filter) => request('statistics/area', 'get', SEARCH_STATISTICS, SEARCH_STATISTICS_SUCCESS, SEARCH_STATISTICS_ERROR, null, { location, query: { ...location, ...filter } });

export const exportStatistics = (location, filter) => request('statistics/area/report', 'get', EXPORT_STATISTICS, EXPORT_STATISTICS_SUCCESS, EXPORT_STATISTICS_ERROR, null, { query: { ...location, ...filter }, download: true });


export const getSavedSearches = refreshed => request('user/new/searches', 'get', GET_SAVED_SEARCHES, GET_SAVED_SEARCHES_SUCCESS, GET_SAVED_SEARCHES_ERROR, null, { refreshed });

export const searchFavorites = query => request('user/new/searches/canned', 'get', SEARCH_FAVORITES, SEARCH_FAVORITES_SUCCESS, SEARCH_FAVORITES_ERROR, null, { query });

export const searchLeadList = query => request('user/searches/leadList', 'get', LEAD_LIST, LEAD_LIST_SUCCESS, LEAD_LIST_ERROR,null, { query });


export const saveSearch = search => request('user/searches', 'put', SAVE_SEARCH, SAVE_SEARCH_SUCCESS, SAVE_SEARCH_ERROR, search);

export const deleteSearch = id => request('user/searches', 'delete', DELETE_SEARCH, DELETE_SEARCH_SUCCESS, DELETE_SEARCH_ERROR, [{ id }]);

export const assignSearchSlot = (id, slot) => request(`user/searches/slots/${slot}/${id}`, 'put', ASSIGN_SEARCH_SLOT, ASSIGN_SEARCH_SLOT_SUCCESS, ASSIGN_SEARCH_SLOT_ERROR);


export const updateLocalFilter = (localFilter) => ({ type: LOCAL_UPDATE_FILTER, localFilter });
export const removeLocalFilter = (id) => ({ type: LOCAL_REMOVE_FILTER, id });
export const clearLocalFilter = (id) => ({ type: LOCAL_CLEAR_FILTER });
export const showAllButtonEditOption = (data) => ({ type: FILTER_SHOW_ALL_EDIT, data });
export const clearButtonEditOption = () => ({ type: FILTER_SHOW_ALL_CLEAR });



export const clearLeadListApiData = () => ({ type: LEAD_LIST_CLEAR });
export const setSelectedSaveSearch = (name) => ({ type: FILTER_SAVE_SEARCH, name });




